import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Image2 from '../assets/Vidaplacentera.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ProgramBenefitsSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#eece47',
        padding: '3rem 1rem',
        textAlign: 'center',
      }}
    >
      {/* Contenedor General con ancho máximo */}
      <Box
        sx={{
          maxWidth: '960px', // Ajusta este valor según tus necesidades
          margin: '0 auto',    // Centra el contenido horizontalmente
        }}
      >
{/* Contenedor del Título */}
<Box
  sx={{
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}
>
  {/* Título con Líneas */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100VW',
    }}
  >
    {/* Línea Izquierda */}
    <Box
      sx={{
        flexGrow: 1,
        height: '2px',
        backgroundColor: '#000',
        maxWidth: { xs: '50px', md: '260px' }, // Ajusta el ancho en diferentes tamaños
        marginRight: '1rem',
      }}
    />
    <Typography
      variant="h4"
      sx={{
        color: '#000',
        fontWeight: 'bold',
        fontSize: { xs: '1.5rem', md: '2rem' },
        textAlign: 'center',
      }}
    >
      ¿Cómo <span style={{ color: '#FFF' }}>cambiará tu vida</span> después de este programa?
    </Typography>
    {/* Línea Derecha */}
    <Box
      sx={{
        flexGrow: 1,
        height: '2px',
        backgroundColor: '#000',
        maxWidth: { xs: '50px', md: '260px' }, // Ajusta el ancho en diferentes tamaños
        marginLeft: '1rem',
      }}
    />
  </Box>
</Box>

        {/* Contenedor Principal */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Apilado en mobile, lado a lado en desktop
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2rem',
          }}
        >
          {/* Columna de Texto */}
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: '100%', md: '50%' }, // Limita el ancho máximo
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' }, // Centrado en mobile
              padding: { xs: '0 1rem', md: '0' },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                marginBottom: '1rem',
                fontSize: { xs: '1.2rem', md: '1.7rem' },
              }}
            >
              Al finalizar las <strong>12 sesiones 1-a-1:</strong>
            </Typography>
            <List>
              <ListItem>
              <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: { xs: '8px', md: '4px' }, // Más juntos en web
                    }}
                  >
                    <CheckCircleIcon sx={{ color: '#FFF' }} /> {/* Ícono personalizado */}
                  </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#000' }}>
                      Sabrás <strong>administrar tu dinero</strong> de forma efectiva y fácil.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
              <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: { xs: '8px', md: '4px' }, // Más juntos en web
                    }}
                  >
                    <CheckCircleIcon sx={{ color: '#FFF' }} /> {/* Ícono personalizado */}
                  </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#000' }}>
                      <strong>Obtendrás y aplicarás conocimientos</strong> para aumentar tus ingresos.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
              <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: { xs: '8px', md: '4px' }, // Más juntos en web
                    }}
                  >
                    <CheckCircleIcon sx={{ color: '#FFF' }} /> {/* Ícono personalizado */}
                  </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#000' }}>
                      Aprenderás a <strong>multiplicar tu dinero</strong>  mediante la inversión.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
              <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: { xs: '8px', md: '4px' }, // Más juntos en web
                    }}
                  >
                    <CheckCircleIcon sx={{ color: '#FFF' }} /> {/* Ícono personalizado */}
                  </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#000' }}>
                      Desarrollarás una <strong>mentalidad millonaria</strong> enfocada en el crecimiento continuo.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>

          {/* Columna de Imagen */}
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: '100%', md: '50%' }, // Limita el ancho máximo
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={Image2}
              alt="Vista inspiradora"
              sx={{
                width: '100%',
                height:'400PX',
                maxWidth: '400px',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramBenefitsSection;
