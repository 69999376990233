import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Imagen4 from '../assets/Rodrigo_Animado-removebg-preview.png';
import { motion } from 'framer-motion';

const CallToActionSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f6f3e0', // Fondo claro
        padding: '3rem 1rem 0 1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        marginTop: '-2rem',
      }}
    >
      {/* Contenedor Izquierdo (Texto Principal) */}
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        style={{ maxWidth: '600px', textAlign: 'center' }}
      >
        {/* Texto Destacado */}
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', md: '2rem' },
              marginBottom: '1rem',
              backgroundColor: '#b6dcf1',
              padding: '0.5rem',
              borderRadius: '8px',
              display: 'inline-block',
            }}
          >
            ¡EMPEZAR TU CAMINO AL MILLÓN NUNCA HA SIDO TAN FÁCIL!
          </Typography>
        </motion.div>

        {/* Descripción */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#000',
              fontSize: '1rem',
              marginBottom: '1.5rem',
            }}
          >
            Como agradecimiento por llegar hasta acá: Podrás agendar{' '}
            <strong>gratis</strong> una <strong>sesión de descubrimiento*</strong> conmigo donde
            obtendrás un <strong>plan de acción personalizado</strong> para ti.
          </Typography>
        </motion.div>

        {/* Botón de Registro */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#eece47',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              padding: '0.5rem 1.5rem',
              marginBottom: '0.5rem',
              textTransform: 'none',
              ':hover': { backgroundColor: '#FFC300' },
            }}
            onClick={() => {
              window.location.href = 'https://forms.gle/BjNQ9TRPMDyFKFjs9';
            }}
          >
            Regístrate aquí
          </Button>
        </motion.div>

        {/* Texto de Precio */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <Typography
            variant="h6"
            sx={{
              textDecoration: 'line-through',
              fontWeight: 'bold',
              color: '#000',
              margin: '0.5rem 0',
            }}
          >
            40 USD
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: '#FFD700',
              fontWeight: 'bold',
            }}
          >
            GRATIS
          </Typography>
        </motion.div>

        {/* Nota */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#000',
              fontSize: '0.8rem',
              marginTop: '1.1rem',
            }}
          >
            *Para brindar un nivel de calidad superior a mis clientes actuales,{' '}
            <strong>los cupos son limitados.</strong>
          </Typography>
        </motion.div>
      </motion.div>

      {/* Contenedor Derecho (Ilustración y Botón) */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',  // Asegura que solo se vea la mitad de la imagen
        }}
      >
        {/* Ilustración */}
        <Box
          component="img"
          src={Imagen4}
          alt="Ilustración"
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',  // Ajusta la imagen para que se recorte si es necesario
            position: 'relative',
            left: '50%',  // Mueve la imagen para que se recorte desde la mitad
            transform: 'translateX(-50%)',  // Centra la imagen desde la mitad
            marginBottom: '-15rem',  // Ajusta el margen inferior si es necesario
          }}
        />

        {/* Botón Conóceme */}
        <Button
          sx={{
            position: 'absolute',
            bottom: '30%',
            left: '55%',
            transform: 'translate(-50%, 50%)',
            backgroundColor: '#eece47',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '1rem',
            padding: '0.5rem 1.5rem',
            borderRadius: '8px',
            textTransform: 'none',
            ':hover': {
              backgroundColor: '#FFC300',
            },
          }}
          onClick={() => {
            window.location.href = 'https://linktr.ee/rodrigo.al.millon';
          }}
        >
          Conóceme
        </Button>
      </motion.div>
    </Box>
  );
};

export default CallToActionSection;
