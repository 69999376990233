import React from 'react';
import { Box, Typography } from '@mui/material';

const VideoSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#eece47', // Fondo claro
        padding: '3rem 1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Título con Líneas Decorativas */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '2rem',
          width: '100vw',
        }}
      >
        {/* Línea Izquierda */}
        <Box
          sx={{
            flex: 1,
            height: '2px',
            backgroundColor: '#000',
            maxWidth: '160px',
          }}
        />
        {/* Título */}
        <Typography
          variant="h4"
          sx={{
            color: '#000',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', md: '2rem' },
            margin: { xs: '0 0.5rem', md: '0 1rem' },
          }}
        >
          ¿Listo(a) para tomar el control de tu <span style={{ color: '#FFF' }}>vida</span> a través de tus{' '}
          <span style={{ color: '#FFF' }}>finanzas</span>?
        </Typography>
        {/* Línea Derecha */}
        <Box
          sx={{
            flex: 1,
            height: '2px',
            backgroundColor: '#000',
            maxWidth: '160px',
          }}
        />
      </Box>

      {/* Contenedor del Video */}
      <Box
        sx={{
          width: '100%',
          maxWidth: '800px',
          aspectRatio: '16/9', // Relación de aspecto para video
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Sombra alrededor del video
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/A6tklCxe5zo" // URL del video
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            border: 'none',
          }}
        ></iframe>
      </Box>
    </Box>
  );
};

export default VideoSection;
