import React from 'react';
import { AppBar, Toolbar, Button, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../assets/Isotipo (1).png';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detectar si es móvil

  return (
    <AppBar
      position={isMobile ? 'static' : 'fixed'}  
      sx={{
        backgroundColor: '#FFF9E6',
        padding: '0.5rem 1rem',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          textAlign: 'center',
        }}
      >
        {/* Logo y título */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          sx={{
            mb: isMobile ? 2 : 0, // Espaciado inferior en móvil para separar del botón
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="Logo"
            sx={{
              height: '40px',
              marginRight: '0.5rem', // Espaciado entre logo y título
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: '#000',
              fontWeight: 'bold',
              fontSize: '1.2rem',
            }}
          >
            Coaching Al Millón
          </Typography>
        </Box>
        {/* Botón Únete */}
        <Box>
          <Button
            variant="contained"
            aria-label="Unirse a nuestra comunidad"
            sx={{
              backgroundColor: '#eece47',
              color: '#fff',
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: isMobile ? '0.9rem' : '1rem',
              padding: isMobile ? '0.5rem 1rem' : '0.7rem 1.5rem',
              ':hover': { backgroundColor: '#FFC300' },
              whiteSpace: 'nowrap',
              marginLeft: !isMobile ? 'auto' : 0,
            }}
            onClick={() => {
              window.location.href = 'https://forms.gle/KmycCtDn23vDFGVr6';
            }}
          >
            Únete a nuestra comunidad aquí
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
