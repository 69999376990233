import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import { CssBaseline, Box } from '@mui/material';
import EligibilitySection from './components/EligibilitySection';
import ProgramBenefitsSection from './components/ProgramBenefitsSection';
import TestimonialsSection from './components/TestimonialsSection';
import FeedbackSection from './components/FeedbackSection';
import VideoSection from './components/VideoSection';
import CallToActionSection from './components/CallToActionSection';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Box sx={{ minHeight: '100vh', paddingBottom:'-2rem',marginBottom: 0}}>
      <Navbar />
      <HeroSection />
      <VideoSection />
      <EligibilitySection />
      <ProgramBenefitsSection />
      <TestimonialsSection />
      <FeedbackSection />
      <CallToActionSection />
    </Box>
    </ThemeProvider>
  );
};

export default App;
