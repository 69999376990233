import React from 'react';
import { Box, Typography } from '@mui/material';
import Sandra from '../assets/Sandra Ramos OFICIAL.png';
import Anthony from '../assets/Anthony Reyes OFICIAL.png';
import Anyela from '../assets/Anyela Arroyo OFICIAL.png';
import Carlos from '../assets/Carlos Rocha OFICIAL.png';

const FeedbackSection = () => {
  const testimonials = [
    {
      imageSrc: Sandra,
      name: 'Sandra Ramos, 33 años.',
      role: 'Asesora de un Call Center.',
    },
    {
      imageSrc: Anthony,
      name: 'Anthony, 20 años.',
      role: 'Diseñador Gráfico.',
    },
    {
      imageSrc: Carlos,
      name: 'Carlos Rocha, 22 años.',
      role: 'Estudiante de Administración.',
    },
    {
      imageSrc: Anyela,
      name: 'Anyela, 24 años.',
      role: 'Supervisora en Planta de Producción.',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: '#b6dcf1',
        padding: '2rem 1rem 6rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '2rem',
      }}
    >
      {/* Contenedor de Mensajes */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr', // Una columna en dispositivos pequeños
            sm: '1fr 1fr', // Dos columnas en tablets
            md: '1fr 1fr', // Dos columnas en escritorio
          },
          gap: {
            xs: '2.1rem',  
            md: '1.6rem',  
          }, 
          maxWidth: '1200px',
          alignItems: 'center',
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              textAlign: 'center',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Contenedor de Imagen con tamaño fijo adaptable */}
            <Box
              sx={{
                width: {
                  xs: '320px',  // 300px en dispositivos pequeños
                  md: '400px',  // 400px en pantallas grandes
                },
                overflow: 'hidden', // Asegura que la imagen no se salga del contenedor
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                margin:{
                  xs: 1, 
                  md: 0,  
                },
              }}
            >
              {/* Imagen del testimonio */}
              <Box
                component="img"
                src={testimonial.imageSrc}
                alt={`Imagen de ${testimonial.name}`}
                sx={{
                  width: '100%', // La imagen llena el ancho del contenedor
                  height: '100%', // La imagen llena la altura del contenedor
                  objectFit: 'contain', // Asegura que la imagen se vea completa sin recortes
                }}
              />
            </Box>
            {/* Nombre y Cargo */}
            <Box
              sx={{
                backgroundColor: '#FFF',
                color: '#000',
                borderRadius: '8px',
                padding: '0.5rem',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                position: 'absolute',
                bottom: '-28px',
                right: '-2px',
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {testimonial.name}
              </Typography>
              <Typography variant="body2">{testimonial.role}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FeedbackSection;
