import React from 'react';
import { Box, Typography, Grid, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icono para puntos de lista
import { motion } from 'framer-motion';
import LogoTienda from '../assets/tienda.png';
import LogoDinero from '../assets/dinero.png';
import LogoInversión from '../assets/inversion (1).png';

const EligibilitySection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f6f3e0',
        padding: '3rem 1rem',
        textAlign: 'center',
      }}
    >
      {/* Título con animación */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '3rem',
          }}
        >
          {/* Línea Izquierda */}
          <Box
            sx={{
              flex: 1,
              height: '2px',
              backgroundColor: '#000',
              maxWidth: { xs: '20px', md: '330px' },
              marginRight: { xs: '0.5rem', md: '1rem' },
            }}
          />
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              color: '#000',
              fontWeight: 'bold',
              fontSize: { xs: '1.3rem', md: '2.1rem' },
              margin: 0,
            }}
          >
            ¿Cómo saber si este programa <span style={{ color: '#FFD700' }}>es para mí?</span>
          </Typography>
          {/* Línea Derecha */}
          <Box
            sx={{
              flex: 1,
              height: '2px',
              backgroundColor: '#000',
              maxWidth: { xs: '20px', md: '330px' },
              marginLeft: { xs: '0.5rem', md: '1rem' },
            }}
          />
        </Box>
      </motion.div>

{/* Logos con animación */}
<Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: { xs: '4rem', md: '12rem' }, // Controla la separación entre logos
    marginBottom: '2rem',
    flexWrap: { xs: 'wrap', md: 'nowrap' }, // Asegura una sola línea en pantallas grandes
  }}
>
  {/* Logo 1 */}
  <motion.div
    initial={{ opacity: 0, y: 30 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay: 0.2 }}
  >
    <Box
      component="img"
      src={LogoTienda}
      alt="Logo Tienda"
      sx={{
        width: { xs: '60px', sm: '100px' }, // Tamaño adaptable
        display: 'block',
      }}
    />
  </motion.div>
  {/* Logo 2 */}
  <motion.div
    initial={{ opacity: 0, y: 30 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay: 0.4 }}
  >
    <Box
      component="img"
      src={LogoDinero}
      alt="Logo Dinero"
      sx={{
        width: { xs: '60px', sm: '100px' }, // Tamaño adaptable
        display: 'block',
      }}
    />
  </motion.div>
  {/* Logo 3 */}
  <motion.div
    initial={{ opacity: 0, y: 30 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay: 0.6 }}
  >
    <Box
      component="img"
      src={LogoInversión}
      alt="Logo Inversión"
      sx={{
        width: { xs: '60px', sm: '100px' }, // Tamaño adaptable
        display: 'block',
      }}
    />
  </motion.div>
</Box>

      {/* Texto y Lista con animación */}
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: '960px',
          margin: '0 auto',
          alignItems: 'center',
        }}
      >
        {/* Texto Principal */}
        <Grid item xs={12} md={4}>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                fontSize: { xs: '1.5rem', md: '2.8rem' },
                textAlign: { xs: 'center', md: 'center' },
              }}
            >
              Si tienes entre <span style={{ color: '#FFD700' }}><br />22-30 años</span> <br /> y...
            </Typography>
          </motion.div>
        </Grid>
        
{/* Lista de Puntos */}
<Grid item xs={12} md={8}>
  <Box sx={{ textAlign: 'left', paddingLeft: { md: '1rem' } }}>
    
    {/* Primer Punto */}
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.2, delay: 1 }}
    >
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 'auto',
            marginRight: { xs: '8px', md: '4px' }, // Más juntos en web
          }}
        >
          <CheckCircleIcon sx={{ color: '#FFD700' }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1" sx={{ fontSize:{ xs:'1.1rem', md:'1.2rem'}, color: '#000' }}>
              Quieres empezar un <strong>emprendimiento exitoso</strong>, pero no sabes cómo.
            </Typography>
          }
        />
      </ListItem>
    </motion.div>

    {/* Segundo Punto */}
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.2, delay: 1.2 }}
    >
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 'auto',
            marginRight: { xs: '8px', md: '4px' },
          }}
        >
          <CheckCircleIcon sx={{ color: '#FFD700' }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1" sx={{ fontSize: { xs:'1.1rem', md:'1.2rem'}, color: '#000' }}>
              Te preocupas constantemente por <strong>despilfarrar tu dinero</strong>.
            </Typography>
          }
        />
      </ListItem>
    </motion.div>

    {/* Tercer Punto */}
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.2, delay: 1.4 }}
    >
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 'auto',
            marginRight: { xs: '8px', md: '4px' },
          }}
        >
          <CheckCircleIcon sx={{ color: '#FFD700' }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1" sx={{ fontSize:{ xs:'1.1rem', md:'1.2rem'}, color: '#000' }}>
              Estás interesado en <strong>aprender a invertir</strong> <br />tu dinero de manera efectiva y simple.
            </Typography>
          }
        />
      </ListItem>
    </motion.div>
  </Box>
</Grid>

      </Grid>
    </Box>
  );
};

export default EligibilitySection;
