import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import IMAGE1 from '../assets/Libertad financiera (1).jpg';

const HeroSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f6f3e0',
        overflowX: 'hidden', // Evita desplazamiento horizontal
        marginBottom:'3rem'
      }}
    >
      {/* Hero Section Header */}
      <Box
        sx={{
          backgroundColor: '#000',
          color: '#FFF',
          padding: '1rem',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: { xs: '1.4rem', sm: '1.5rem', md: '2rem' },
          margin: '2rem auto',
          marginTop:{xs:'2.5rem', md:'6.5rem'},
          maxWidth: { xs: '95%', sm: '85%', md: '960px' },
          borderRadius: '8px',
        }}
      >
        ¡ATENCIÓN,{' '}
        <span style={{ color: '#FFD700' }}>JÓVENES VISIONARIOS</span> EN PERÚ!
      </Box>

      {/* Wrapper Box for Main Content Grid */}
      <Box
        sx={{
          padding: '1rem', // Añadimos el mismo padding que el header
          maxWidth: { xs: '95%', sm: '85%', md: '960px' }, // Mismo maxWidth que el header
          margin: '0 auto', // Centrado horizontal
        }}
      >
        {/* Main Content Grid */}
        <Grid
          container
          spacing={4}
          sx={{
            alignItems: 'center',
            justifyContent: 'center', // Asegura el centrado total
          }}
        >
          {/* Text Column */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', md: 'flex-start' }, // Texto centrado en mobile
              textAlign: { xs: 'center', md: 'left' }, // Alineación del texto
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                lineHeight: '1.3',
                marginBottom: '1rem',
                fontSize: { xs: '1.9rem', sm: '2rem', md: '2.9rem' },
              }}
            >
              DESCUBRE EL <span style={{ color: '#FFD700' }}><br />MÉTODO AGM</span>,<br />
              USADO POR EL <span style={{ color: '#FFD700' }}>1.5%</span><br /> DE LA POBLACIÓN.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#000',
                fontSize: { xs: '1.1rem', sm: '1.8rem' },
              }}
            >
              Aprende a controlar tus finanzas de manera <strong><br />fácil y eficiente.</strong>
            </Typography>
          </Grid>

          {/* Image Column */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', // Imagen centrada
            }}
          >
            <Box
              component="img"
              src={IMAGE1}
              alt="Hombre en barco"
              sx={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                maxHeight: { xs: '300px', sm: '400px', md: '480px' },
                objectFit: 'cover',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeroSection;
