import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const TestimonialsSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#b6dcf1', // Fondo azul claro
        padding: '3rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Contenedor del Título con Líneas con animación */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '2rem',
            width: '100%',
          }}
        >
          {/* Línea Izquierda */}
          <Box
            sx={{
              flex: 1,
              height: '2px',
              backgroundColor: '#fff',
              maxWidth: { xs: '20px', md: '300px' },
              marginRight: { xs: '0.5rem', md: '1rem' },
            }}
          />
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', md: '2rem' },
              margin: 0,
            }}
          >
            ¿Qué dicen otros{' '}
            <span>
              <span style={{ color: '#FF0000' }}>per</span>
              <span style={{ color: '#FFF' }}>ua</span>
              <span style={{ color: '#FF0000' }}>nos</span>
            </span>{' '}
            sobre su experiencia?
          </Typography>
          {/* Línea Derecha */}
          <Box
            sx={{
              flex: 1,
              height: '2px',
              backgroundColor: '#fff',
              maxWidth: { xs: '20px', md: '300px' },
              marginLeft: { xs: '0.5rem', md: '1rem' },
            }}
          />
        </Box>
      </motion.div>

            {/* Texto Final con animación */}
            <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.5 }}
        style={{ marginTop: '0.5rem', maxWidth: '800px', marginBottom:'2.5rem' }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
              color: '#fff',
              marginBottom: '0.5rem',
            }}
          >
            Una tasa de satisfacción del 100% entre alumnos actuales:
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#fff' }}>
            “Superaste todas las expectativas que tenía en la primera sesión”. <br /> – Miguel Aguilar Bardales
          </Typography>
        </Box>
      </motion.div>

      {/* Contenedor de Testimonios con animación */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Apilado en mobile
          justifyContent: 'center',
          alignItems: 'center', // Centrar contenido en mobile
          gap: '2rem', // Espaciado entre columnas
          maxWidth: '1200px',
          width: '100%',
        }}
      >
        {/* Array de testimonios para mapear */}
{[
  {
    name: 'Abilene Olaechea, 30 años.',
    description: 'Puesto de Jefatura en Planta. \nQuímica Farmacéutica.',
    videoId: 'nxqowrBlQAw',
    delay: 0.2,
  },
  {
    name: 'Mariet Triveños, 24 años.',
    description: 'Dueña de Centro Ortopédico. \nAbogada y Periodista.',
    videoId: 'YxXA0-Iuq04',
    delay: 0.4,
  },
  {
    name: 'Carolina Li, 23 años.',
    description: 'Estudiante de Psicología.',
    videoId: 'XkF8O0W-qQg',
    delay: 0.6,
  },
].map((testimonial, index) => (
  <motion.div
    key={index}
    initial={{ opacity: 0, y: 30 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay: testimonial.delay }}
  >
    <Box
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        height: '350px',
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        component="iframe"
        src={`https://www.youtube.com/embed/${testimonial.videoId}`}
        title={`Video de ${testimonial.name}`}
        sx={{
          width: '100%',
          height: '300px',
          border: 'none',
        }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Box sx={{ padding: '1rem', textAlign: 'center' }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            marginBottom: '0.5rem',
            color: '#000',
          }}
        >
          {testimonial.name}
        </Typography>
        <Typography variant="body2" sx={{ color: '#000' }}>
          {testimonial.description.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      </Box>
    </Box>
  </motion.div>
))}
      </Box>
    </Box>
  );
};

export default TestimonialsSection;
